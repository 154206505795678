import { useCallback, useMemo } from 'react'
import useCredit from '@/hooks/useCredit'
import IconCredit from '@/public/assets/diamond.svg'
import LoadingButton from '@/components/loading-button'
import { cls } from '@/utils'
import { useSetAtom } from 'jotai'
import { insufficientDialogOpenAtom } from '@/atoms'

interface CreditSpendButtonProps {
  className?: string
  text: string
  spu:
    | 'generation/txt2vid'
    | 'generation/img2vid'
    | 'upscale/vid2vid'
    | 'extend/vid2vid'
    | 'generation/txt2img'
    | 'repainting/vid2vid'
  duration: number
  resolution: number | null
  onClick: Function
}
const CreditSpendButton = ({ text, spu, duration, resolution, onClick, className }: CreditSpendButtonProps) => {
  const { varifyCredit, getCreditPrice } = useCredit()
  const setInsufficientOpen = useSetAtom(insufficientDialogOpenAtom)

  const price = useMemo(() => {
    return getCreditPrice({
      mode: spu,
      duration,
      resolution,
    })
  }, [spu, duration, resolution, getCreditPrice])

  const confirmClick = useCallback(async () => {
    if (varifyCredit(price)) {
      await onClick?.()
    } else {
      setInsufficientOpen(true)
    }
  }, [onClick, setInsufficientOpen, varifyCredit, price])

  return (
    <LoadingButton variant='primary' className={cls('flex-1 rounded-md', className)} onClick={confirmClick}>
      <div className='flex gap-2 items-center'>
        <div>{text}</div>
        <div className='flex gap-1 px-1 h-5 rounded-sm items-center bg-black-opacity-15 text-body-sm'>
          <IconCredit className='size-4' />
          {price}
        </div>
      </div>
    </LoadingButton>
  )
}

export default CreditSpendButton
